var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "van-pull-refresh",
        {
          staticStyle: { "min-height": "100vh" },
          attrs: { "success-text": "刷新成功" },
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.isLoading,
            callback: function($$v) {
              _vm.isLoading = $$v
            },
            expression: "isLoading"
          }
        },
        [
          _vm.ishow
            ? [
                _c("van-empty", {
                  staticClass: "custom-image",
                  attrs: {
                    image: require("@/assets/images/pic_no information@2x.png"),
                    description: "暂无数据!"
                  }
                })
              ]
            : _vm._e(),
          !_vm.ishow
            ? [
                _c(
                  "div",
                  _vm._l(_vm.selllist, function(items) {
                    return _c(
                      "div",
                      { key: items.id },
                      _vm._l(items.orderList, function(itemslist, index) {
                        return itemslist.show
                          ? _c(
                              "div",
                              {
                                key: index,
                                on: {
                                  click: function($event) {
                                    return _vm.hrefdetails(
                                      itemslist.orderId,
                                      itemslist.payStatus,
                                      itemslist.orderType
                                    )
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "flex" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "left",
                                      style: {
                                        background:
                                          itemslist.orderType == "6"
                                            ? "#39394D"
                                            : "#39394D"
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "text" }, [
                                        _vm._v(
                                          _vm._s(
                                            itemslist.orderType == "6"
                                              ? "违约结算"
                                              : "来料结算"
                                          )
                                        )
                                      ])
                                    ]
                                  ),
                                  _c("div", { staticClass: "right" }, [
                                    _c("div", { staticClass: "orderInfo" }, [
                                      _c(
                                        "div",
                                        { staticClass: "flex_between" },
                                        [
                                          _c(
                                            "h1",
                                            { staticClass: "comeWight" },
                                            [
                                              _vm._v(
                                                "来料重量 " +
                                                  _vm._s(
                                                    itemslist.incomingWeight
                                                  ) +
                                                  "g"
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "state stateash",
                                              class: {
                                                stateGreen:
                                                  itemslist.payStatus == "1" ||
                                                  itemslist.payStatus == "-1"
                                                    ? true
                                                    : false,
                                                stateYellow:
                                                  itemslist.payStatus == "0"
                                                    ? true
                                                    : false
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  itemslist.payStatus == "0"
                                                    ? "待付款"
                                                    : itemslist.payStatus == "1"
                                                    ? "已付款"
                                                    : itemslist.payStatus ==
                                                      "-1"
                                                    ? "已完成"
                                                    : "待确认"
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "BrokenWeight" },
                                        [
                                          _vm._v(
                                            "折足重量：" +
                                              _vm._s(itemslist.weight) +
                                              "g"
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("div", { staticClass: "orderNmber" }, [
                                      _c(
                                        "div",
                                        { staticClass: "flex_between" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "number van-ellipsis"
                                            },
                                            [
                                              _vm._v(
                                                "订单单号: " +
                                                  _vm._s(itemslist.orderNo)
                                              )
                                            ]
                                          ),
                                          _c("div", { staticClass: "time" }, [
                                            _vm._v(_vm._s(itemslist.date))
                                          ])
                                        ]
                                      )
                                    ])
                                  ])
                                ])
                              ]
                            )
                          : _vm._e()
                      }),
                      0
                    )
                  }),
                  0
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }