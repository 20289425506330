var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "SellingPrice" },
    [
      _c(
        "van-pull-refresh",
        {
          staticStyle: { "min-height": "100vh" },
          attrs: { "success-text": "加载成功" },
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing"
          }
        },
        [
          _vm.ishow
            ? [
                _c("van-empty", {
                  staticClass: "custom-image",
                  attrs: {
                    image: require("@/assets/images/pic_no information@2x.png"),
                    description: "暂无数据!"
                  }
                })
              ]
            : _vm._e(),
          !_vm.ishow
            ? _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": "没有更多了"
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                _vm._l(_vm.list, function(item, index) {
                  return item.show
                    ? _c(
                        "div",
                        {
                          key: index,
                          staticClass: "cell",
                          on: {
                            click: function($event) {
                              return _vm.ToSellPricingDetails(item)
                            }
                          }
                        },
                        [
                          _c("div", {
                            staticClass: "leftLine",
                            style: {
                              background:
                                item.status == "FAIL" ? "#39394D" : "#39394D"
                            }
                          }),
                          _c("div", { staticClass: "content" }, [
                            _c("div", { staticClass: "top" }, [
                              _c("div", { staticClass: "title" }, [
                                _c("div", { staticClass: "name" }, [
                                  _vm._v(
                                    _vm._s(item.goods[0].name) +
                                      " " +
                                      _vm._s(item.weight.toFixed(2)) +
                                      "g"
                                  )
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "state stateash",
                                    class: {
                                      stateGreen:
                                        item.status == "DONE" ||
                                        item.status == "VIOLATE"
                                          ? true
                                          : false,
                                      stateBlue:
                                        item.status == "UNDELIVERED"
                                          ? true
                                          : false,
                                      stateYellow:
                                        item.status == "RECEIVE" ? true : false
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.status == "SUSPENSE"
                                            ? "待审核"
                                            : item.status == "UNDELIVERED"
                                            ? "待交料"
                                            : item.status == "DONE" ||
                                              item.status == "VIOLATE"
                                            ? "已完成"
                                            : item.status == "RECEIVE"
                                            ? "待处理"
                                            : item.status == "CANCEL"
                                            ? "已取消"
                                            : "已拒绝"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]),
                              item.hasOwnProperty("businessType")
                                ? _c("div", { staticClass: "color999" }, [
                                    _vm._v(
                                      "业务类型 : " +
                                        _vm._s(
                                          item.businessType == "0"
                                            ? "即期交料"
                                            : "延期交料"
                                        )
                                    )
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "color999" }, [
                                _vm._v(
                                  "未交料重量 : " +
                                    _vm._s(item.freeWeight.toFixed(2)) +
                                    "g"
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "bottom" }, [
                              _c(
                                "div",
                                { staticClass: "color999 van-ellipsis" },
                                [_vm._v("订单单号: " + _vm._s(item.oid))]
                              ),
                              _c("div", { staticClass: "color999" }, [
                                _vm._v(
                                  _vm._s(_vm._f("formatDate_")(item.createTime))
                                )
                              ])
                            ])
                          ])
                        ]
                      )
                    : _vm._e()
                }),
                0
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }