<template>
  <div class="SellingPrice">
    <van-pull-refresh v-model="refreshing" success-text="加载成功" @refresh="onRefresh" style="min-height: 100vh;">
        <template v-if="ishow">
       <van-empty
           class="custom-image"
           :image="require('@/assets/images/pic_no information@2x.png')"
           description="暂无数据!"
         />
        </template>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          v-if="!ishow"
        >
            <div class="cell"  v-for="(item,index) in list" :key="index" @click="ToSellPricingDetails(item)" v-if="item.show">
                <div class="leftLine" :style="{background:item.status == 'FAIL'?'#39394D':'#39394D'}"></div>
                <div class="content">
                    <div class="top">
                        <div class="title">
                             <div class="name">{{item.goods[0].name}} {{item.weight.toFixed(2)}}g</div>
                             <div class="state stateash" :class="{
                               stateGreen:item.status == 'DONE' || item.status == 'VIOLATE'?true:false,
                               stateBlue: item.status == 'UNDELIVERED' ? true : false,
                               stateYellow: item.status == 'RECEIVE' ? true : false
                               }" >
                                 {{item.status == 'SUSPENSE'?'待审核':item.status == 'UNDELIVERED'?'待交料':item.status == 'DONE' || item.status == 'VIOLATE'?'已完成' :item.status == 'RECEIVE' ?'待处理':item.status == 'CANCEL'?'已取消':'已拒绝'}}
                            </div>
                        </div>
                        <div class="color999" v-if="item.hasOwnProperty('businessType')">业务类型 : {{item.businessType == '0'?'即期交料':'延期交料'}}</div>
                        <div class="color999">未交料重量 :   {{item.freeWeight.toFixed(2)}}g</div>
                    </div>
                    <div class="bottom">
                        <div class="color999 van-ellipsis">订单单号: {{item.oid}}</div>
                        <div class="color999">{{item.createTime | formatDate_ }}</div>
                    </div>
                </div>
            </div>
        </van-list>
    </van-pull-refresh>

  </div>
</template>

<script>
import *as api from'@/utils/api'
export default {
    props:['activeName'],
    data() {
        return {
             list: [],
             loading: false,
             finished: false,
             refreshing: false,
             page:0,
             size:20,
            //  没有数据显示的
             ishow:true,
          haveData: false,
        }
    },
methods: {
    ToSellPricingDetails(item){
            localStorage.setItem('SellPricingDetails',JSON.stringify(item))
            this.$router.push({
                name:'sellPricingDetails',
                query:{id:item.oid}
            })
        },
    onLoad() {
        setTimeout(async() => {
            try{
                let res = await api.getSellingPriceList({
                    page:this.page,
                    size:this.size,
                    status:this.activeName})
                // 如果加载完数据了 last为true
                // 把上一段 number赋值给page
                this.page = res.number +1
              const nowTime = new Date().getTime()
              res.content.forEach(item => {
                const timeDiff = nowTime - item.createTime * 1000
                if(Math.floor(timeDiff / (24 * 3600 * 1000)) > 7 && item.status !== 'UNDELIVERED') {
                  item.show = false
                } else {
                  this.haveData = true
                  item.show = true
                }
              })

                // 把数组里的每一项一一铺开添加
                this.list.push(...res.content)

                // console.log(this.list);
                // 本次加载完毕，就应该把loading改成false，代表本次加载完了，让加载中状态消失
                // 这样才可以继续下次加载
                   this.loading = false;
                if (res.last == true) {
                    this.finished = true;
                }
              }catch{
                    this.ishow = true
              }finally{

              }
            },1000)

        },
async   onRefresh() {
                this.finished = false
            try{
                        //请求最新的数据
                let res =  await api.getSellingPriceList({
                    page:0,
                    size:this.size,
                    status:this.activeName})
              const nowTime = new Date().getTime()
              res.content.forEach(item => {
                const timeDiff = nowTime - item.createTime * 1000
                if(Math.floor(timeDiff / (24 * 3600 * 1000)) > 7 && item.status !== 'UNDELIVERED') {
                  item.show = false
                } else {
                  this.haveData = true
                  item.show = true
                }
              })
                        this.page = res.number+1
                        this.list = res.content
                console.log('1',this.list);
                }catch{
                        this.ishow = true
                }finally{
                    if (this.list.length == 0 || !this.haveData) {
                        this.ishow = true
                        this.refreshing = false
                        return false
                    }else{
                        this.ishow = false
                    }
                        this.refreshing = false
                        this.loading = false
                    }
        },
  },
    created(){
        console.log(this.list);
        this.onRefresh()
    },
}
</script>

<style lang="scss" scoped>
#loadingbox {
  margin-top: 150px;
}
.van-empty {
  margin-top: 150px;
}
::v-deep{
    .van-loading__text{
        font-size: 30px;
    }
    .van-list__finished-text{
        font-size: 30px;
    }
    .van-pull-refresh__head{
      font-size: 30px !important;
    }
    .van-empty__image{
        width: 420px;
        height: 260px;
    }
}
.SellingPrice{
    background-color: #f5f5f5;
}
.van-list{
    padding:  30px;
}
.top{
    border-bottom: 1px solid #E9E9E9;
    padding: 10px 0 20px;
}
.cell{
    background-color: #fff;
    display: flex;
    border-radius: 4px 4px 4px 4px;
    margin-bottom: 30px;
    overflow: hidden;
    .content{
        width: 100%;
        padding:20px;
        .title{
        display: flex;
        justify-content: space-between;
        font-size: 28px;
        .weight{
            text-align: center;
        }
        .state{
          width: 112px;
          height: 56px;
          line-height: 56px;
          font-size: 24px;
          text-align: center;
          border-radius: 6px;
        }
        .stateash{
          background: #F1F3F7;
          color: #8F8F9F;
        }
        .stateGreen{
          background: #E6FFFA !important;
          color: #00AD8C !important;
        }
          .stateBlue{
            background: #E6F4FF !important;
            color: #33A5FF !important;
          }
          .stateYellow{
            background: #FFF2E6 !important;
            color: #FF7A00 !important;
          }
        .name{
            color: #242424;
            font-weight: 500;
            font-size: 32px;
        }
        }
        .bottom{
        display: flex;
        justify-content: space-between;
        padding: 16px 0 0;
        .color999{
            font-size: 24px;
        }
        }
        .color999{
            color: #999999;
            font-size: 28px;
            margin: 10px 0 0 0;
        }
    }
    .leftLine{
        width: 8px;
        height: 260px;
        background: #39394D;
        border-radius: 8px 0px 0px 8px;
        opacity: 1;
    }
}
.color999.van-ellipsis {
    width: 330px;
}
</style>
