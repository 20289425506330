<template>
  <div>
    <van-pull-refresh
       v-model="isLoading"
       success-text="刷新成功"
       @refresh="onRefresh"
       style="min-height: 100vh;"
>

    <!-- <template >
          <van-empty
            class="custom-image"
            image="https://img.yzcdn.cn/vant/custom-empty-image.png"
            description="暂无数据"
          />
        </template> -->
      <!-- 卖出结算 -->
      <template v-if="ishow">
       <van-empty
           class="custom-image"
           :image="require('@/assets/images/pic_no information@2x.png')"
           description="暂无数据!"
         />
      </template>
      <template v-if="!ishow">
        <!-- 个人中心待确认跳转过来的，只显示待确认页面 -->
        <!-- <div v-if="isTobeconfirmed"> -->
        <!-- <div v-if="havedataboolean"> -->
          <div>
          <div v-for="(items) in selllist" :key="items.id" >
            <div
              v-for="(itemslist, index) in items.orderList"
              :key="index"
              @click="hrefdetails(itemslist.orderId, itemslist.payStatus,itemslist.orderType)"
              v-if="itemslist.show"
            >
              <div class="flex">
                    <div class="left" :style="{background:itemslist.orderType == '6'?'#39394D':'#39394D'}">
                      <div class="text" >{{itemslist.orderType == '6'?'违约结算':'来料结算'}}</div>
                    </div>
                    <div class="right">
                          <div class="orderInfo">
                                <div class="flex_between">
                                  <h1 class="comeWight">来料重量   {{ itemslist.incomingWeight }}g</h1>
                                  <div class="state stateash" :class="{
                               stateGreen:itemslist.payStatus == '1' || itemslist.payStatus == '-1'?true:false,
                               stateYellow: itemslist.payStatus == '0' ? true : false
                               }">{{
                          itemslist.payStatus == "0"
                            ? "待付款"
                            : itemslist.payStatus == "1"
                            ? "已付款"
                            : itemslist.payStatus == "-1"
                            ?'已完成'
                            : "待确认"
                        }}</div>
                          <!-- <div class="status">{{text}}</div> -->
                                </div>
                                <div class="BrokenWeight">折足重量：{{ itemslist.weight }}g</div>
                          </div>
                          <div class="orderNmber">
                                <div class="flex_between">
                                      <div class="number van-ellipsis">订单单号: {{itemslist.orderNo}}</div>
                                      <div class="time">{{itemslist.date}}</div>
                                </div>
                          </div>
                    </div>

                <!-- <div class="flex-between detailsbox">
                  <div class="flex">
                    <div>
                      <div class="sellbox">卖</div>
                    </div>
                    <div class="product_msg ">
                      <div>
                        <div class="f30_black">
                          来料重量：{{ itemslist.incomingWeight }}g
                        </div>
                        <div class="f30_black">
                          折足重量：{{ itemslist.weight }}g
                        </div>
                        <div class="f24_gray">{{ itemslist.date }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="flex onlyicon">
                    <div>
                      <div
                        class="f32"
                        :class="
                          itemslist.payStatus == '0'
                            ? 'PendPayment'
                            : itemslist.payStatus == '1'
                            ? 'overgetmoney'
                            : 'toConfirmed'
                        "
                      >
                        {{ itemslist.money }}
                      </div>
                      <div
                        class=" f24 tr mart20"
                        :class="
                          itemslist.payStatus == '0'
                            ? 'PendPayment'
                            : itemslist.payStatus == '1'
                            ? 'overgetmoney'
                            : 'toConfirmed'
                        "
                      >
                        {{
                          itemslist.payStatus == "0"
                            ? "待付款"
                            : itemslist.payStatus == "1"
                            ? "已付款"
                            : "待确认"
                        }}
                      </div>
                    </div>
                    <div class="iconbox">
                      <div><i class="el-icon-arrow-right"></i></div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
            <!-- 数据为空 -->
          </div>
        </div>
        <!-- <div v-else>
          <van-empty
            class="custom-image"
            image="https://img.yzcdn.cn/vant/custom-empty-image.png"
            description="暂无数据"
          />
        </div> -->
        <!-- </div> -->

        <!-- <div v-if="!isTobeconfirmed">
          <div v-for="items in selllist" :key="items.id">
            <div class="time">{{ items.date }}</div>
            <div
              v-for="(itemslist, index) in items.orderList"
              :key="index"
              @click="hrefdetails(itemslist.orderId, itemslist.payStatus)"
            >
              <div class="flex-between detailsbox">
                <div class="flex">
                  <div>
                    <div class="sellbox">卖</div>
                  </div>
                  <div class="product_msg ">
                    <div>
                      <div class="f30_black">
                        来料总重：{{ itemslist.weight }}g
                      </div>
                      <div class="f24_gray">{{ itemslist.date }}</div>
                    </div>
                  </div>
                </div>
                <div class="flex onlyicon">
                  <div>
                    <div
                      class="f32"
                      :class="
                        itemslist.payStatus == '0'
                          ? 'PendPayment'
                          : itemslist.payStatus == '1'
                          ? ''
                          : 'toConfirmed'
                      "
                    >
                      {{ itemslist.money }}
                    </div>
                    <div
                      class=" f24 tr mart20"
                      :class="
                        itemslist.payStatus == '0'
                          ? 'PendPayment'
                          : itemslist.payStatus == '1'
                          ? 'overgetmoney'
                          : 'toConfirmed'
                      "
                    >
                      {{
                        itemslist.payStatus == "0"
                          ? "待付款"
                          : itemslist.payStatus == "1"
                          ? "已付款"
                          : "待确认"
                      }}
                    </div>
                  </div>
                  <div class="iconbox">
                    <div><i class="el-icon-arrow-right"></i></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </template>
    </van-pull-refresh>
  </div>
</template>
<script>
import * as JieSuanOrderListApi from "@/utils/api";
export default {
  data() {
    return {
      // havedataboolean: false,
      ishow: true,
      selllist: [],
      // 是否待确认
      isTobeconfirmed: false,
      // 待确认列表
      newproductlist: [],
      // 是否下拉加载状态
      isLoading: false,
      text:'' //状态文字
    };
  },
  mounted() {
    if (this.$route.query.sellMaterials) {
      this.isTobeconfirmed = true;
    }

    JieSuanOrderListApi.queryJieSuanOrderList({ orderType: 0 })
      .then(res => {
        console.log('res',res);
        if (res.code == 0) {
          this.selllist = res.data;
          const nowTime = new Date().getTime()
          let haveData = false
          this.selllist.forEach(item => {
            item.orderList.forEach(item1 => {
              const timeDiff = nowTime - new Date(item1.date.replace(/-/g, "/")).getTime()
              if(Math.floor(timeDiff / (24 * 3600 * 1000)) > 7 && (item1.payStatus == "1" || item1.payStatus == "-1")) {
                item1.show = false
              } else {
                haveData = true
                item1.show = true
              }
            })
          })
          if (this.selllist.length != 0 && haveData) {
              this.ishow = false;
          }else{
            this.ishow = true
          }
           this.selllist.forEach((item,index)=>{
              item.orderList.forEach((items,index)=>{

              })
            console.log('item',item);
           })

          this.showyear();
          // for (const b in this.selllist) {
          //   for (const i in this.selllist[b].orderList) {
          //     //  六改:   判断订单类型 ：付款状态 0 待付款 1 已付款 2 待确认
          //     if (this.selllist[b].orderList[i].orderType == 0) {
          //       this.havedataboolean = true;
          //     }
          //   }
          // }
        }
      })
      .catch(err => {
        this.ishow = true;
      });
  },
  methods: {
    // 下拉事件
 async   onRefresh(){
     try{
        let res =  await   JieSuanOrderListApi.queryJieSuanOrderList({ orderType: 0 })
         console.log('res',res);
        if (res.code == 0) {
          this.selllist = res.data;
          const nowTime = new Date().getTime()
          let haveData = false
          this.selllist.forEach(item => {
            item.orderList.forEach(item1 => {
              const timeDiff = nowTime - new Date(item1.date.replace(/-/g, "/")).getTime()
              if(Math.floor(timeDiff / (24 * 3600 * 1000)) > 7 && (item1.payStatus == "1" || item1.payStatus == "-1")) {
              item1.show = false
            } else {
                haveData = true
              item1.show = true
            }
            })
          })
          if (this.selllist.length != 0 && haveData) {
              this.ishow = false;
          }else{
            this.ishow = true
          }
          console.log('this.selllist', this.selllist);
          this.showyear();
          this.$Loading.close()
        //   for (const b in this.selllist) {
        //     for (const i in this.selllist[b].orderList) {
        //       //  六改:   判断订单类型 ：0 卖出  1 压盘预付  2 压盘尾款
        //       if (this.selllist[b].orderList[i].orderType == 0) {
        //         this.havedataboolean = true;
        //       }
        // }
        //   }
          }
       } catch(err){
        this.ishow = true;
      };
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
    // 年份时间里是否包含待确认数据
    showyear() {
      const selllist_ = this.selllist;
      for (const i in selllist_) {
        this.newproductlist.push({
          year: selllist_[i].date,
          ishavadata: false
        });
        // console.log('newproductlist:',this.newproductlist);
        // debugger;
        for (const b in selllist_[i].orderList) {
          //  卖出订单
          if (selllist_[i].orderList[b].orderType == 0) {
            //  每单的时间？
            const getyear = selllist_[i].orderList[b].date.split(" ")[0];
            const yearmonth =
              getyear.split("-")[0] + "-" + getyear.split("-")[1];

              // console.log('year:'+ getyear);
              // console.log('month:', yearmonth);

            for (const item in this.newproductlist) {
              if(this.$route.query.sellMaterials){
                this.newproductlist[item].ishavadata = false;
              }else if (this.newproductlist[item].year == yearmonth) {
                this.newproductlist[item].ishavadata = true;
              }

              // console.log('newproductlist:'+ this.newproductlist[item]);
            }
          }
        }
      }
    },
    hrefdetails(orderId, paytype,orderType) {
      if (orderType==6) {
          this.$router.push({
            name: "default",
            query: { orderid: orderId, payType: paytype }
      });
      } else {
          this.$router.push({
            name: "selldetails",
            query: { orderid: orderId, payType: paytype }
      });
      }
    }
  },

};
</script>

<style lang="scss" scoped="true">
#loadingbox {
  margin-top: 150px;
}
::v-deep{
    .van-loading__text{
        font-size: 30px;
    }
    .van-list__finished-text{
        font-size: 30px;
    }
    .van-pull-refresh__head{
      font-size:30px !important;
    }
        .van-empty__image{
        width: 420px;
        height: 260px;
    }

}
.van-empty {
  margin-top: 150px;
}
::v-deep{
  .van-pull-refresh{
    padding: 30px 40px 40px;
  }
}
.flex_between{
  display: flex;
  justify-content: space-between;
}
.flex{
  width: 100%;
  display: flex;
  margin-bottom: 40px;
  .left{
    width: 40px;
    height: 212px;
    background-color: #39394D;
    padding: 46px 0;
    border-radius: 8px 0px 0px 8px;
    .text{
      width: 24px;
      height: 120px;
      font-size: 24px;
      color: #F6D0AB;
      line-height: 30px;
      margin: auto;
    }
  }
  .right{
    width: 100%;
    background-color:#fff;
    height: 212px;
    padding: 0 20px;
    .orderInfo{
        padding: 30px 0;
        border-bottom: 1px solid #E9E9E9;
              .comeWight{
                  color: #242424;
                  font-size: 32px;
                  font-weight: 500;
                  line-height: 42px;
              }
              .status{
                  width: 120px;
                  height: 48px;
                  text-align: center;
                  line-height: 48px;
                  background-color: #fff;
                  border: 1px solid #FF2D2E;
                  border-radius: 50px;
                  font-size: 28px;
                  color: #FF2D2E;
              }
              .BrokenWeight{
                color: #999999;
                font-size: 28px;
                line-height: 34px;
              }
      }
    .orderNmber{
        padding: 20px 0;
        color: #999999;
        font-size: 24px;
        .number{
          width: 320px;
        }
      }
  }
}
.state{
  width: 112px;
  height: 56px;
  line-height: 56px;
  font-size: 24px;
  text-align: center;
  border-radius: 6px;
  margin-top: -6px;
}
.stateash{
  background: #F1F3F7;
  color: #8F8F9F;
}
.stateGreen{
  background: #E6FFFA !important;
  color: #00AD8C !important;
}
.stateYellow{
  background: #FFF2E6 !important;
  color: #FF7A00 !important;
}
</style>
